import React from 'react';
import '../App/App.css';
import '../About/About.css';
import { useParallax } from 'react-scroll-parallax';

const About = () => {
    const parallax = useParallax({
        speed: 30,
    });

    const parallaxText1 = useParallax({
        speed: 20,
    });

    const parallaxText2 = useParallax({
        speed: 20,
    });

    const parallaxText3 = useParallax({
        speed: 20,
    });

    return (
        <div ref={parallax.ref}>
            <h1 className='sectionHeader' ref={parallax.ref} >
                About Me
            </h1>
            <div id='About' className='aboutSection section' >
                <div>
                    <p ref={parallaxText2.ref}>
                        I've worked in sales, QA, and two-way radio -- now I'm turning my sights on full stack web development! I love getting things 
                        to communicate and work together, preferably in some clever way. 
                    </p>
                    <p ref={parallaxText1.ref}>
                        I worked for many years in sales before I discovered my interest in coding. I started teaching myself, but I soon 
                        applied to the ICS program at Camosun College to get some more insight into the industry and learn about different 
                        frameworks and technologies.
                    </p>
                    <p ref={parallaxText3.ref}>
                        Feel welcome to reach out via LinkedIn if you'd like to collaborate on a project or if you think I might be a good fit for your team. If 
                        you're in the Victoria area, I would be glad to meet up! Whether you just want to talk shop or otherwise get some face-to-face time, 
                        we can always grab a coffee or a beer. 
                    </p>
                </div>
            </div>
        </div>
    )
};

export default About;