import React from 'react';
import Hero from './Hero';
import About from '../About/About';
import Projects from '../Projects/Projects';
import { ParallaxProvider } from 'react-scroll-parallax';

const Home = () => {
    return (
        <>
            <ParallaxProvider>
                <Hero />
                <About />
                <Projects />
            </ParallaxProvider>
            <footer>
                <div className='footerbar'>
                    <p>Created by Steven Sonvisen.</p>
                    <div className='socials'>
                        <a 
                            href='https://github.com/idaeusice'
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img src={require('../../images/github.png')} alt='github logo'/>
                        </a>
                        <a 
                            href='https://linkedin.com/in/ssonvisen'
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img src={require('../../images/linkedin.png')} alt='linkedin logo'/>
                        </a>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Home;