import React, { useState } from 'react';
import Project from './Project';
import projectList from './projectList';
import '../App/App.css';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import PanoramaFishEyeIcon from '@mui/icons-material/PanoramaFishEye';
import CircleIcon from '@mui/icons-material/Circle';
import '../Projects/Projects.css';

const Projects = () => {
    const [currProject, setCurrProject ] = useState(0); 

    const increasePage = () => {
        if(currProject < projectList.projectList.length - 1)
            setCurrProject(currProject + 1);
        if(currProject === projectList.projectList.length - 1)
            setCurrProject(0);
    };

    const decreasePage = () => {
        if(currProject > 0)
            setCurrProject(currProject - 1);
        if(currProject === 0)
            setCurrProject(projectList.projectList.length - 1);
    };

    const setPage = (page) => {
        setCurrProject(page);
    };

    return (
        <>
            <h1 id='projects-header' className='sectionHeader'>Projects</h1>
            <div id='Projects' className='projectsContainer'>
                <div className='page-left' onClick={decreasePage}> 
                    <ChevronLeft fontSize='large'/>
                </div>

                <Project className='project' currProject={projectList.projectList[currProject]} key={currProject}/>

                <div className='page-right' onClick={increasePage}>
                    <ChevronRight fontSize='large'/>
                </div>
            </div>

            <div className='pagination'>
                {
                    projectList.projectList.map((proj) => {
                        if(projectList.projectList.indexOf(proj) === currProject){
                            return <CircleIcon className='pagination-icons' key={proj.id} onClick={() => setPage(proj.id)}/>
                        } else {
                            return <PanoramaFishEyeIcon className='pagination-icons' key={proj.id} onClick={() => setPage(proj.id)}/>
                        }
                    })
                }
            </div>
        </>
    )
}

export default Projects;