import React from 'react';
import { useParallax } from 'react-scroll-parallax';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const Hero = () => {
    const heroDescParallax = useParallax({
        speed: 20,
    });
    const heroImgParallax = useParallax({
        speed: 10,
    });

    const video = 'https://steven-site.s3.us-west-1.amazonaws.com/Coding%232.mp4';

    return (
        <>
            <div className="hero">
                <video className='hero-img' ref={heroImgParallax.ref} autoPlay loop muted>
                    <source src={video} type='video/mp4' />
                </video>
                <section id="Home" ref={heroDescParallax.ref} className="header section">
                    <h1>
                        Steven Sonvisen
                    </h1>
                    <h6>
                        I like to build cool web apps leveraging new technologies, languages, and my past experiences.  
                    </h6>
                    <div className='downArrow'>
                        <span><KeyboardArrowDownIcon fontSize='large'/></span>
                    </div>
                </section>
            </div>
        </>
    )
}

export default Hero;