import React, { useEffect } from 'react';
import '../App/App.css';
import './Resume.css';
import CircleIcon from '@mui/icons-material/Circle';
import Tooltip from '@mui/material/Tooltip';
import workExperience from './workExperience';
import ScrollReveal from 'scrollreveal';
import { useNavigate } from 'react-router';

const Resume = () => {
    const work = workExperience.workExperience;

    useEffect(() => {
        ScrollReveal().reveal('.timeline-node', 
            {
                distance: '20%', 
                duration: 1000, 
                viewOffset: {
                    top: 50,
                    bottom: 50
                }
            }
        );
    }, []);

    const nav = useNavigate();

    return (
        <div id='resume'>
            <div className='timeline-card'>
                <h1 style={{color: 'white', borderBottom: 'none'}}>Work Experience</h1>
                <div className='map'>

                </div>

                <div className='timeline'>
                    <div className='timeline-jobs'>
                        {
                            work.map((job) => {
                                return (
                                    <div className='timeline-node' key={job.id}>
                                        <div className='timeline-year'>{job.year}</div>
                                        <CircleIcon size='large'/>
                                        <div className='timeline-horiz'></div>
                                        <div className='timeline-job-details'>
                                            <div className='timeline-node-title'>{job.name}</div>
                                            <div className='timeline-node-skills timeline-subtitle'>{job.skills.map((skill) => {
                                                const image = require(`../../images/skills/${skill}.png`);

                                                return (
                                                image != null ? 
                                                    <Tooltip 
                                                        title={skill.slice(0,1).toUpperCase() + skill.slice(1, skill.length)}
                                                        key={job.id + job.skills.indexOf(skill)}
                                                    >
                                                        <img 
                                                            className='skill-image' 
                                                            src={image} 
                                                            alt={skill} 
                                                        />
                                                    </Tooltip>
                                                    : null
                                                )
                                            })}</div>
                                            <div className='timeline-node-company timeline-subtitle' onClick={nav(job.link)}>{job.company}</div>
                                            <div className='timeline-node-desc'>{job.desc}</div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Resume;