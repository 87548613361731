import React, { useState } from 'react';
import '../Projects/Projects.css';

const Project = (props) => {
    const image = require(`../../images/projectImages/project${props.currProject.id}.PNG`);
    const [ loaded, setLoaded ] = useState(false);

    return (
        <>
            <div className='project-title'>{props.currProject.title}</div>
            <div className='project-card' key={props.currProject.id}>
                <img 
                    className='project-image' 
                    key={props.currProject.id} 
                    src={image} 
                    alt={'image sample for ' + props.currProject.title} 
                    onLoad={() => setLoaded(true)}
                />
                {
                    !loaded ? <div className='loader'></div> 
                    : 
                    <>
                        
                        <div className='project-desc-card'>
                            <div className='project-desc'>{props.currProject.description}</div>
                        </div>
                    </>
                }
            </div>
        </>
    )
}

export default Project;