import React, { useState } from 'react';
import NavLink from './NavLink';
import '../App/App.css';

const Nav = () => {
    const navs = ['Home', 'About', 'Projects'];
    const [ menuOpen, setMenuOpen ] = useState(false);

    const toggleMenu = (e) => {
        setMenuOpen(!menuOpen);
    };

    return (
        <>
            {
                //based on a design by Jesse Couch https://codepen.io/designcouch/pen/ExvwPY
                <div id="nav-icon" className={menuOpen ? `open` : ``} onClick={toggleMenu}>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            }
            
            <nav className={menuOpen ? `navbar menu-opened` : `navbar menu-closed`}>
                {
                    menuOpen ?
                        <>
                            {
                                navs.map((elem) => {
                                    return <NavLink className='navlink' linkname={elem} key={elem}/>
                                })
                            }
                            {
                                <a 
                                href={'/resume'}
                                className={"navlink"}
                                >
                                Resume
                                </a>
                            }
                        </>
                    : null
                }
            </nav>
        </>
    )
}

export default Nav;