import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from '../Home/Home';
import Nav from '../Nav/Nav';
import Resume from '../Resume/Resume';
import About from '../About/About';
import { ParallaxProvider } from 'react-scroll-parallax';

const App = () => {
  return (
    <>
      <Nav />
        <Router>
          <Routes>
            <Route path='/' element={<Home />} /> 
            <Route path='/about' element={<ParallaxProvider><About /></ParallaxProvider>} />
            <Route path='/resume' element={<Resume />} />
          </Routes>
        </Router>
      <footer>
        <div className='socials'>
          <a 
              href='https://github.com/idaeusice'
              target="_blank"
              rel="noopener noreferrer"
          >
              <img src={require('../../images/github.png')} alt='github logo'/>
          </a>
          <a 
              href='https://linkedin.com/in/ssonvisen'
              target="_blank"
              rel="noopener noreferrer"
          >
              <img src={require('../../images/linkedin.png')} alt='linkedin logo'/>
          </a>
        </div>
        <p>If you're seeing this, you may be in the wrong place.</p>
      </footer>
    </>
  );
}

export default App;