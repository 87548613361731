import React, { useState, useEffect } from 'react';

const NavLink = ({linkname, page}) => {
    const [anchorTarget, setAnchorTarget] = useState(null);

    useEffect(() => {
        setAnchorTarget(document.getElementById(linkname));
    }, [linkname]);

    const handleClick = e => {
        if(window.location.href.indexOf('resume') > -1 || window.location.href.indexOf('about') > -1){
            anchorTarget.scrollIntoView({ behavior: 'smooth', block: 'start' }); 
        } else {
            e.preventDefault();
            anchorTarget.scrollIntoView({ behavior: 'smooth', block: 'start' }); 
        }
    };

    return (
        <>
            <a 
                href={`/#${linkname}`}
                onClick={handleClick}
                className={"navlink"}
            >
                {linkname}
            </a>
        </>
    );
}

export default NavLink;