export default {
    projectList: [
        {
            id: 0,
            title: 'Woo Woo Network',
            technologies: 'HTML, CSS, React, Node, Express, MySQL',
            description: `Woo Woo Network was a project initiated for a local business looking to 
                build a network of trusted alternative healers. Using the React, Node, Express, and MySQL
                technologies, and google maps integration, users can easily identify local healers by setting
                filters for services offered, service delivery format, range, or city. `,
            image: ''
        },
        {
            id: 1,
            title: 'Daintree',
            technologies: 'PHP, JavaScript, Oracle, Apache, HTML, CSS',
            description: `Daintree was a 3-person project used to showcase php and database knowledge
                by creating a simple shopping application. It uses an admin-level login to 
                add, remove, and adjust item stock.`,
            image: ''
        },
        {
            id: 2,
            title: 'First Person Shooter',
            technologies: 'Unity, C#',
            description: `This project is a basic FPS game written in Unity with C#. It features simple shooter 
            game mechanics, score, and a UI. `,
            image: ''
        },
        {
            id: 3,
            title: 'RTS Space Game',
            technologies: 'Unity, C#',
            description: `This project is a loosely RTS-based space combat game, focused on targeting specific ship components, collecting 
            reinforcement tokens, and guiding ships onto the battlefield. `,
            image: ''
        }
    ]
};