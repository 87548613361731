export default {
    workExperience: [
        {
            id: 1,
            name: 'Full Stack Developer',
            company: 'Foundry Spatial',
            link: 'https://www.foundryspatial.com/',
            year: 2022,
            skills: ['JavaScript', 'GitHub', 'CSS', 'Cypress'],
            desc: `Actively working with the development team to add functionality, make bug fixes, and ensure mobile compatibility with active projects 
            using the Vue.js framework. Also leveraging Cypress.io to build an automated test suite that works in the project's CI pipeline. `
        },
        {
            id: 2,
            name: 'Contract Full Stack Web Developer',
            company: 'The Unforgotten Ones',
            link: 'https://www.theunforgottenones.com/',
            year: 2022,
            skills: ['react', 'node', 'ExpressJS', 'sql', 'Cypress', 'GitHub', 'CSS'],
            desc: `Handled project development tasks related to creating and maintaining a MySQL database, ExpressJS API, and React frontend. Throughout
            the "Woo Woo Network" project, I produced various reports, status updates, and held meetings directly with the project sponsor. Implemented Firebase and 
            Google Maps integrations. `
        },
        {
            id: 3,
            name: 'Quality Assurance Tester / IT',
            company: 'FunctionFox Systems, Inc.',
            link: 'https://www.functionfox.com/',
            year: 2018,
            skills: ['DOTNET', 'QA', 'CSharp', 'sql', 'Communication', 'Cypress'],
            desc: `Performed primarily manual testing, day-to-day IT tasks, and web development using the .NET technology stack. Researched and developed 
            an automated test suite using Cypress.io. In addition, performed SQL queries and customer database audits as needed by the sales and services teams.`
        },
        {
            id: 4,
            name: 'Account Manager',
            company: 'FunctionFox Systems, Inc.',
            link: 'https://www.functionfox.com/',
            year: 2016,
            skills: ['Sales', 'Communication', 'Office365', 'Funnel-Management'],
            desc: `Engaged in various sales methodologies to build the FunctionFox client base, working directly with business owners and stakeholders to 
            identify business needs, find an appropriate FunctionFox product to suit those needs, and close the sale. This work was performed 
            through emails, cold calls, inbound calls, GoToMeeting/Zoom meetings, and in-person. Also handled customer onboarding and made 
            numerous efforts to follow-up with customers to ensure they were being looked after. `
        },
        {
            id: 5,
            name: 'Regional Sales Contractor',
            year: 2015,
            company: 'Telus/Procom',
            link: 'https://www.telus.com/',
            skills: ['Sales', 'Communication', 'Travel', 'Funnel-Management'],
            desc: `Working in the Victoria area, performed face-to-face sales calls for small business owners. Attended weekly sales meetings to discuss 
            barriers, sales tactics, and sales funnels. Using Salesforce, managed sales leads by region - contacts not in the Victoria area were contacted 
            by phone. Continuously researched new Telus products and promotions that could be offered to potential customers. `
        },
        {
            id: 6,
            name: 'Two-Way Radio Technician',
            year: 2014,
            company: 'Pacific Coast Communications',
            link: 'https://www.pcoast.ca/',
            skills: ['Sales', 'Communication', 'Travel', 'Radio'],
            desc: `Programmed, repaired, and installed two-way radios in a variety of vehicles and locations all over the Okanagan. Vehicles ranged 
            from cars and trucks, to industrial vehicles and vans, to excavators and graters. Radio installation required analysis of business 
            radio frequencies to appropriately trim antennae. Care and a keen attention to detail was needed to install radios - particularly in new vehicles. `
        }
    ]
};